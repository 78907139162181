<template>
	<section>
		<Hero v-if="hero" :data="hero" class="py-6" />
	</section>

	<section>
		<section class="form py-5">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6 mb-5 mb-md-0">
						<h2 class="font-responsive mb-4">How to get in touch</h2>

						<ul class="mb-4">
							<li class="font-mid mb-3"><a href="mailto:hello@kaluna.co.uk">hello@kaluna.co.uk</a></li>
							<li class="font-mid"><a href="tel:07934359933">07934 359 933</a></li>
						</ul>

						<Socials/>

						<a class="calendly" href="https://calendly.com/kalunaweb/website-meeting?month=2021-04" target="_blank" title="Arrange a meeting">Arrange a meeting via Calendly &rarr;</a>
					</div>
					
					<div class="col-12 col-md-6">
						<form @submit.prevent="handleSubmit" name="contact" action="/thanks" method="post" data-netlify="true">
							<div v-if="false !== form_response" class="form-response mb-4 font-mid" v-html="form_response"></div>

							<div class="form-group row no-gutters flex-column-reverse">
								<input required @change="hasData" type="text" v-model="form.name" id="name">
								<label for="name">What shall we call you?</label>
							</div>

							<div class="form-group row no-gutters flex-column-reverse">
								<input required @change="hasData" type="email" v-model="form.email" id="email">
								<label for="email">Your email address?</label>
							</div>

							<div class="form-group row no-gutters flex-column-reverse">
								<textarea required @change="hasData" v-model="form.message" id="message" cols="10" rows="10"></textarea>
								<label for="message">How can we help?</label>
							</div>

							<input type="hidden" name="form-name" value="contact" />

							<div class="button-wrap">
								<button type="submit" id="submit" class="button-item">
									<span>Send</span>
									<div class="button-mask"></div>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script>
	import Socials from '@/components/partials/Socials';

	export default {
		data() {
			return {
				form: [],
				hero: [],
				form_response: false
			}
		},

		components: {
			Socials
		},

		mounted() {
			this.hero.title = 'Contact';
		},

		methods: {
			hasData(input) {
				if ( '' !== input.target.value ) {
					input.target.classList.add('complete');
				} else {
					input.target.classList.remove('complete');
				}
			},

			encode(data) {
				return Object.keys(data)
					.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
					.join("&")
			},

			handleSubmit() {
				fetch("/thanks", {
					method: "POST",
					headers: { 
						"Content-Type": "application/x-www-form-urlencoded" 
					},
					body: new URLSearchParams( this.encode(
							{
								"form-name": event.target.getAttribute("name"),
								...this.form
							}
						)
					)
				})
				.then(response => {
					if (200 === response.status) {
						this.$router.push({name: 'Thanks'})
					}
				})
				.catch(error => alert(error))
			}
		}
	}
</script>

<style lang="scss">
	input[type="text"],
	input[type="email"],
	textarea {
		display: block;
		outline: none;
		border-radius: none;
		border: none;
		color: color(mono, black);
		border-bottom: 3px solid currentColor;
		width: 100%;
		padding-bottom: 3px;

		&:active,
		&:focus,
		&:hover {
			& + label {
				color: color(mono, black);
			}
		}

		&.complete {
			color: color(brand, accent);

			& + label {
				color: color(brand, accent);
			}
		}
	}

	textarea {
		max-height: 100px;
	}

	label {
		font-size: font(mid);
		color: color( mono, dark );
		font-weight: 900;
		width: 100%;

		&:hover {
			cursor: pointer;
		}
	}

	.form-group {
		&:not(:last-child) {
			margin-bottom: 3rem;
		}
	}

	input#submit:disabled {
		opacity: 0.33;
	}

	.form-response {
		background-color: color(brand, accent);
		padding: 10px 15px;
		color: color(mono, white);
		font-weight: 600;
		opacity: 0;
		animation: slide-in 600ms ease-in-out forwards;
	}

	.calendly {
		font-weight: 900;
	}
</style>